@import 'styles/texts';

.filterContainer {
  align-items: left;

  .text {
    @include typography('dense', 14px, normal);

    display: inline;
    color: var(--gray100);
  }

  .chipWrapper {
    margin-top: 8px;

    .chip {
      display: inline-block;
    }

    .chip + .chip {
      margin-left: 8px;
    }
  }
}
