.contentsDetailWrapper {
  display: grid;
  overflow: hidden;
}

// react-transition-group 用のスタイル
// - https://reactcommunity.org/react-transition-group/css-transition

@mixin transition-set {
  transition: grid-template-rows 250ms ease-in-out;
}

@mixin folded {
  grid-template-rows: 0fr !important;
}

@mixin expanded {
  grid-template-rows: 1fr !important;
}

.enter,
.exit {
  @include transition-set;
}

// enter → enterActive → enterDone
.enter {
  @include folded;
}

.enterActive,
.enterDone {
  @include expanded;
}

// exit → exitActive → exitDone
.exit {
  @include expanded;
}

.exitActive,
.exitDone {
  @include folded;
}
