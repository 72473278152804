@import 'styles/texts';

.tagGroups {
  display: grid;
  row-gap: 24px;
}

.tagGroup {
  display: grid;
  row-gap: 16px;
}

.tagGroupHeader {
  display: flex;
  justify-content: space-between;
}

.tagGroupGroup {
  @include typography('dense', 14px, bold);

  color: var(--gray100);
}

.checkboxesWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.divider {
  margin-top: 24px;
}
