@import 'styles/texts';
@import 'styles/libs';

.cardWrapper {
  @include isSp {
    display: grid;
    min-height: 100%;
  }
}

.contentsWrapper {
  @include isSp {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100%;
  }
}

.contentsInner {
  @include isSp {
    padding: 16px;
    border-top: 1px solid var(--gray20);
  }
}

.initialText {
  @include typography('normal', 20px, bold);

  color: var(--gray60-alpha);

  @include isSp {
    @include typography('normal', 14px, bold);
  }
}

.paginationWrapper {
  padding: 8px 24px 24px;
  text-align: right;

  @include isSp {
    padding: 8px 16px 16px;
  }
}
