.base {
  display: flex;
  align-content: stretch;
}

.searchTextFieldsContainer {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  margin-right: 12px;
  background: var(--white100);
  border: 1px solid var(--gray20);
  border-radius: 4px;
}

.input {
  display: flex;
}

.verticalLine {
  margin: 0 calc(16px - 1px) 0 16px;
  margin-right: calc(16px - 1px);
  border-left: 1px solid var(--gray20);
}
