@import 'styles/texts';

.checkboxesWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.divider {
  margin-top: 24px;
}
