@import 'styles/libs';
@import 'styles/texts';

$pagination-height: 8px + 16px + 36px ;

.base {
  @include isSp {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: calc(100% - #{$pagination-height});
  }
}

.head {
  @include typography('dense', 18px, bold);

  padding: 16px 24px;
  background-color: var(--gray5);

  @include isSp {
    @include typography('dense', 16px, bold);

    padding: 16px;
  }
}

.wrapper {
  padding: 24px;
  background: var(--white100);

  @include isSp {
    padding: 16px;
  }
}

.item + .item {
  margin-top: 16px;
}

.noItemWrapper {
  @include isSp {
    padding: 16px;
  }
}

.noItem {
  color: var(--gray60-alpha);

  @include isSp {
    @include typography('normal', 14px, bold);
  }
}
