@import 'styles/texts';
@import 'styles/libs';

.container {
  display: flex;
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid var(--gray20);
  border-radius: 4px;

  @include isSp {
    flex-direction: column;
    align-items: unset;
    padding: 16px;
  }
}

.medicalInstitutionName {
  @include typography('dense', 18px, bold);

  color: var(--primary-blue50);

  @include isSp {
    @include typography('normal', 16px, bold);
  }
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @include isSp {
    display: none;
  }

  & + .medicalInstitutionName {
    margin-top: 16px;

    @include isSp {
      margin-top: 0;
    }
  }
}

.itemList {
  margin-top: 16px;

  @include isSp {
    margin-top: 12px;
  }
}

.item {
  display: flex;
  align-items: center;
  color: var(--gray60-alpha);

  @include isSp {
    align-items: unset;
  }

  & + & {
    margin-top: 8px;
  }
}

.icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 8px;
}

.text {
  @include typography('normal', 14px, normal);
}

.linkButtonWrapper {
  @include isSp {
    margin-top: 12px;
  }
}

.linkButton {
  @include isSp {
    display: flex;
  }
}
