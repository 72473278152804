@import 'styles/texts';

.trigger {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
}

.title {
  @include typography('normal', 16px, bold);
}

.icon {
  width: 24px;
  height: 24px;
}

.contents {
  display: grid;
  row-gap: 16px;
  padding: 16px;
}
