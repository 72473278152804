@import 'styles/texts';

.base {
  @include typography('dense', 14px, normal);

  display: inline-block;
  padding: 8px;
  color: var(--gray100);
  background-color: var(--gray5);
  border: 1px solid var(--gray20);
  border-radius: 5px;
}
