@import 'styles/texts';

.departments {
  display: grid;
  row-gap: 24px;
}

.department {
  display: grid;
  row-gap: 16px;
}

.departmentHeader {
  display: flex;
  justify-content: space-between;
}

.departmentGroup {
  @include typography('dense', 18px, bold);

  color: var(--gray100);
}

.checkboxesWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
