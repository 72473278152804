@import 'styles/texts';

.base {
  margin-top: 24px;
}

.title {
  @include typography('dense', 16px, bold);

  color: var(--gray100);
}

.content {
  margin-top: 12px;
}
