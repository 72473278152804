@import 'styles/libs';
@import 'styles/texts';

.base {
  padding: 24px;

  @include isSp {
    padding: 0;
  }
}

.filter {
  margin-top: 24px;

  @include isSp {
    display: none;
  }
}
